import React, {useState, useRef, useContext, useEffect, useCallback} from "react";
import { Layout, Menu, Button, Space, Dropdown, Col, Row, Typography } from 'antd';
import { Cascader, message } from 'antd';
import Toggle from '../toggle/Toggle'
import i18next from 'i18next'
import moment from "moment";

import {
  SmileOutlined,
  DownOutlined
} from '@ant-design/icons';

// Helpers
import { 
    //getPathFromZoneId, 
    getSelectorItemsFromZoneResponse, 
 //   sendListObjectClickedAnalytics 
} from '../../utilities/data'
import { fetchZone } from '../../utilities/request'
//FIXME - Analytics
//import analytics from '~/analytics/analytics'

import "./MapsideZone.css"

import { getZoneFromQuery, getZoneFromResponse } from '../../utilities/data'
import VegetationIndexLegend from "./VegetationIndexLegend";
import IndexTimeline from "./IndexTimeline";
import { GlobalContext } from "../../App";
import SatelliteDataService from "../../services/SatelliteData";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import MapLegend from "./CropMapLegend";
import RegionsService from "../../services/Regions";
import RegionsCascader from "../regions-cascader/RegionsCascader";
import CropMapLegendSeasons from "./CropMapLegend-Seasons";
import { useTranslation } from "react-i18next";
//import InfoZone from "./InfoZone"; // TODO - Where the fuck is it?
import useWindowDimensions from "../../utilities/window_size";

const {Sider, Footer, Header} = Layout;

//  FIXME - VEGETATION INDEX MENU - 


function MapSidezone(props) {
  const { t, i18n } = useTranslation("translation");
  const { height, width } = useWindowDimensions();
    // State
    const [isSelectorVisible, setIsSelectorVisible] = useState(true);
    const [indices, setIndices] = useState([]);
    //const [selected_index, setSelectedIndex] = useState("");
    const [index_overlay_with_dates, setIndexOverlayWithDates] = useState({});
    const [nearest_date_to_now, setNearestDateToNow] = useState(""); // date nearest to current moment 

    const {
      current_view,
      setCurrentView, 
      selected_map_layer, 
      setSelectedMapLayer, 
      query_params, 
      setQueryParams,
      selected_user_asset,
      setSelectedUserAsset,
      messageAPI, 
      contextHolder,
      selected_index_overlay,
      setSelectedIndexOverlay,
      selected_index, 
      setSelectedIndex,
      globalFieldDrawUIEnabled,
      setGlobalFieldDrawUIEnabled,
      globalCCViewEnabled,
      setGlobalCCViewEnabled,
      setGlobalSelectedRegion,
      global_map_center,
      setGlobalMapCenter
    } = useContext(GlobalContext);

    useEffect(() => {
      if(current_view?.viewName !== "Home"){
        setSelectedIndex(null)
      }
    }, [current_view])

    useEffect(() => {
      const _indices = [
      {
        key: "1",
        value: "ndvi",
        label: 'NDVI',
        disabled: (globalFieldDrawUIEnabled || !selected_user_asset)
      },
      {
        key: "2",
        value: 'ndmi',
        label: 'NDMI',
        disabled: (globalFieldDrawUIEnabled || !selected_user_asset),
      },
      {
        key: "3",
        value: 'ndwi',
        label: 'NDWI',
        disabled: (globalFieldDrawUIEnabled || !selected_user_asset),
      },
      {
        key: "10",
        value: 'ndre',
        label: 'NDRE',
        disabled: (globalFieldDrawUIEnabled || !selected_user_asset),
      },
      {
        key: "11",
        value: 'nbri',
        label: 'NBRI',
        disabled: (globalFieldDrawUIEnabled || !selected_user_asset),
      },
      {
        key: "4",
        value: "constrast_ndvi",
        label: 'Contrast NDVI',
        icon: <SmileOutlined />,
        disabled: true,
      },
      {
        key: "5",
        value: "evi",
        label: 'EVI',
        disabled: (globalFieldDrawUIEnabled || !selected_user_asset),
      },
      {
        key: "6",
        value: 'rvi',
        label: 'RVI',
        disabled: true,
      },
      {
        key: "7",
        value: 'irrigation',
        label: 'Irrigation',
        disabled: true,
      },

      {
        key: "8",
        value: 'gci',
        label: 'GCI',
        disabled: (globalFieldDrawUIEnabled || !selected_user_asset),
      },
      {
        key: "9",
        value: 'sentinel2',
        label: 'Sentinel - 2',
        disabled: (globalFieldDrawUIEnabled || !selected_user_asset),
      },
    ];

    setIndices(_indices)
  }, [globalFieldDrawUIEnabled, selected_user_asset])
   
    // When user selects an index from index-selector
    const indexSelectorOnChange = (value) => {
      if (!value) {
        setSelectedIndex(null);
        setIndexOverlayWithDates(null);
        setSelectedIndexOverlay(null);
      } else {
        setSelectedIndex(value);
      }
    }

    return (
      <>
      <Header width={400} style={{
        position: "absolute",
        backgroundColor: "transparent", 
        textAlign: "left",
        height: "10vh",
        width: "77vw", // left menu collapsed (3vw) + left sidebar (20vw ) takes 23vw
        zIndex: 1000,
        paddingLeft: 10
        }}
      >
        <Space className="mapside-zone-cascaders" direction="horizontal" split wrap style={{ backgroundColor: "transparent" }}>
          {
            isSelectorVisible &&
            <RegionsCascader />
          }
          { props.showIndices &&
            <Cascader
              key="index-selector"
              options={indices}
              onChange={indexSelectorOnChange} 
              changeOnSelect
              placeholder={
                "Select Index"
              }
              style={{
                marginTop: 0
              }}
            />
          }

          {
            <Button 
              type={globalCCViewEnabled ? "primary" : "default"}
              onClick={() => {setGlobalCCViewEnabled(!globalCCViewEnabled); !!globalCCViewEnabled && setGlobalSelectedRegion({});}}>
              {t("general.crop_intelligence")}
            </Button>
          }
        </Space>
      </Header>
      <Footer style={{
        position: "absolute",
        backgroundColor: "transparent",
        pointerEvents: "none", 
        textAlign: "left",
        height: props.showCropLegend && "10vh", 
        bottom: 0,
        width: width <= 1680 ? "calc(100% - 400px)" : "82.5vw", // left menu collapsed (2.5vw w > 1680, 50px w <= 1680) + left sidebar (15vw w > 1680, 350px w <= 1680), takes 17.5vw or 400px,
        zIndex:1000,
        padding: 0,
      }}>
        <Layout style={{width: "100%", backgroundColor: "transparent", padding: 10}}>
          <div style={{pointerEvents: "auto", textAlign: "center", width: "100%"}}>
            {
              selected_index &&
              <IndexTimeline selected_index={selected_index[0]} span={16} />
            }
          </div>
          <Row style={{backgroundColor: "transparent"}}>
            <Col span={4} style={{pointerEvents: "none"}}></Col>
            <Col style={{pointerEvents: "auto", textAlign: "center"}} span={16}>
             
            </Col>
            <Col span={4} style={{pointerEvents: "none"}}></Col>
          </Row>
          {
            //TODO: This should be more dynamic as we are going to show NDWI, RVI, EVI etc and they'll all need a legend
            // selected_index && selected_index[0] === "ndvi" &&
            // <VegetationIndexLegend />
          }

          {
            props.showCropLegend && !props.crop && 
            <MapLegend />
          }
          {
            props.showCropLegend && props.crop && 
            <MapLegend crop={props.crop} />
          }

          {
            // if an index is selected from the indexselector, we don't show the crop legend; we show the index legend in the action sheet instead, see src/components/sidebars/left-sidebar/ActionSheet.js
            props.showCropLegendSeasons && !selected_index &&  
            <CropMapLegendSeasons />
            }
        </Layout>
      </Footer>
      </>
    );
  }

export default MapSidezone;